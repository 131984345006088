<template>
  <v-hover v-model="hover">
    <v-card class="v-card--game" elevation="6">
      <router-link :to="`/store/codes/${value.id}`">
        <!-- <router-link :to="{ name: 'DetailPage', params: { id: 1, abc: 3 } }"> -->
        <v-img
          v-bind="$attrs"
          :height="height"
          :src="require(`@/assets/${value.img}`)"
          style="border-radius: inherit"
        >
          <v-row
            :style="styles"
            align="center"
            class="fill-height ma-0 transition-swing"
            justify="center"
          >
            <!-- <v-img
              :src="require(`@/assets/${value.bg1}`)"
              contain
              max-width="180"
              style="z-index: -1"
            /> -->
          </v-row>
        </v-img>
        <v-hover v-model="hover">
          <v-sheet
            :color="hover ? 'secondary' : 'transparent'"
            class="transition-swing"
            height="64"
            style="border-radius: 0 0 6px 6px"
          >
            <v-row class="fill-height ma-0" align="center" justify="center">
              <div class="pa-3">
                <span> {{ value.title }} </span>
              </div>
            </v-row>
          </v-sheet>
        </v-hover>
      </router-link>
    </v-card>
  </v-hover>
</template>

<script>
// Utilities
import { mapState } from "vuex";

export default {
  name: "Game",

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    hover: false,
  }),

  computed: {
    ...mapState("verify", ["verifying"]),
    height() {
      // if (this.tall) return 524;
      // if (this.dense) return 150;
      // if (this.prominent) return 600;
      return 150;
    },
    styles() {
      let backgroundColor;

      if (this.understate) {
        backgroundColor = "rgba(117, 117, 117, .72)";
      } else if (!this.static && this.hover) {
        backgroundColor = "rgba(255, 255, 255, .16)";
      }

      return {
        backgroundColor,
      };
    },
  },

  methods: {},
};
</script>
